<template>
  <div :id="id" :class="['m-product-solution', isPC ? 'z-web' : 'z-phone', theme.primaryColor ? theme.primaryColor : '']">
    <VisualItem :visualKeys="title.visualKeys" @visual="handleVisual" class="m-main-title">
      <p class="content">
        {{ title.value }}
      </p>
    </VisualItem>
    <VisualItem :visualKeys="subTitle.visualKeys" @visual="handleVisual" class="m-second-title">
      <p class="content">
        {{ subTitle.value }}
      </p>
    </VisualItem>
    <VisualItem v-if="isPC" :visualKeys="list.visualKeys" @visual="handleVisual" class-name="m-card-wrap">
      <a-tabs :default-active-key="0" tab-position="left" class="m-card">
        <a-tab-pane
          v-for="(item, index) in list.value"
          :key="index"
          :tab="item.title.value"
        >
          <VisualItem :visualKeys="[item.image.visualKeys, item.image_2x.visualKeys]" @visual="handleVisual" class="m-card m-card-border">
            <CMSImage
              v-if="(item.image && item.image.value) && (item.image_2x && item.image_2x.value)"
              :img="item.image ? item.image.value : ''"
              :img2x="item.image_2x ? item.image_2x.value : ''"
              class-name="card-img"
            />
            <VisualItem :visualKeys="[item.img.visualKeys, item.img_2x.visualKeys]" @visual="handleVisual" class="m-line">
              <CMSImage
                v-if="(item.img && item.img.value) && (item.img_2x && item.img_2x.value)"
                :img="item.img ? item.img.value : ''"
                :img2x="item.img_2x ? item.img_2x.value : ''"
                class="m-img"
              />
            </VisualItem>
            <VisualItem :visualKeys="item.tabname.visualKeys" @visual="handleVisual" class="card-title">
              {{ item.tabname.value }}
            </VisualItem>
            <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual" class-name="card-desc">
              {{ item.desc.value }}
            </VisualItem>
          </VisualItem>
        </a-tab-pane>
      </a-tabs>
    </VisualItem>
    <VisualItem v-else :visualKeys="list.visualKeys" @visual="handleVisual" tip="编辑列表">
      <div
        v-for="(item, index) in list.value"
        :key="index"
        :class="['m-card', current === index ? 'f-active' : '']"
        @click="openDesc(index)"
      >
        <VisualItem :visualKeys="[item.image_2x_h5.visualKeys, item.image_3x_h5.visualKeys]" @visual="handleVisual" class="imgBg-wrap">
          <CMSImageBg
            v-if="(item.image_2x_h5 && item.image_2x_h5.value) && (item.image_3x_h5 && item.image_3x_h5.value)"
            :img="item.image_2x_h5 ? item.image_2x_h5.value : ''"
            :img2x="item.image_3x_h5 ? item.image_3x_h5.value : ''"
            class-name="card-img"
          >
            <div class="card-header">
              <VisualItem :visualKeys="[item.img_2x_h5.visualKeys, item.img_3x_h5.visualKeys]" @visual="handleVisual" class-name="m-line">
                <CMSImage
                  v-if="(item.img_2x_h5 && item.img_2x_h5.value) && (item.img_3x_h5 && item.img_3x_h5.value)"
                  :img="item.img_2x_h5 ? item.img_2x_h5.value : ''"
                  :img2x="item.img_3x_h5 ? item.img_3x_h5.value : ''"
                  class="m-img"
                />
              </VisualItem>
              <VisualItem :visualKeys="item.tabname.visualKeys" @visual="handleVisual" class="card-title">
                {{ item.tabname.value }}
              </VisualItem>
              <div class="openIcon">
                <img v-if="current === index" src="/productSolution/h5/closeIcon.svg">
                <img v-else src="/productSolution/h5/openIcon.svg">
              </div>
            </div>
            <VisualItem :visualKeys="item.desc.visualKeys" @visual="handleVisual" class-name="card-desc">
              {{ item.desc.value }}
            </VisualItem>
          </CMSImageBg>
        </VisualItem>
      </div>
    </VisualItem>
  </div>
</template>
<script>
import _ from 'lodash'
import config from './config.json'
import Load from '~/assets/js/load.js'
import { fixConfig } from '~/assets/js/visualFix'
import VisualItem from '~/components/common/VisualItem'
import CMSImage from '~/components/common/Image'
import CMSImageBg from '~/components/common/ImageBg'

const componentKey = 'ProductSolution'

export default {
  components: {
    VisualItem,
    CMSImage,
    CMSImageBg
  },
  props: {
    id: {
      type: [String, Number],
      default: componentKey
    },
    mode: {
      type: String,
      default: ''
    },
    reqData: {
      type: Object,
      default() {
        return {}
      }
    },
    cmsVisual: {
      type: Boolean,
      default: false
    },
    isPC: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const data = fixConfig(_.cloneDeep(config[componentKey]), this.mode === 'dev' ? {
      id: componentKey,
      reqData: Load(config, componentKey)
    } : this.$store.getters.getComponent(this.id))

    this.$store.commit('updateComponentById', {
      id: this.id || componentKey,
      data
    })

    return {
      ...data,
      current: 0,
      imgOnload: null,
      imgList: []
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    }
  },
  mounted() {
    console.log(this.list.value)
    // this.imgOnload = new Image()
    // this.imgOnload.src = this.list.value[1].image_2x.value
    this.list.value.forEach((element) => {
      let imgOnload = new Image()
      imgOnload.src = element.image_2x.value
      this.imgList.push(imgOnload)
      imgOnload = null
    })
  },
  methods: {
    callback(val) {
      console.log(val)
    },
    openDesc(index) {
      this.current = index
    },
    handleVisual(obj) {
      this.$emit('visual', obj)
    }
  }
}
</script>

<style lang="postcss">
@import '~/assets/css/4mixin.css';

.m-product-solution {
  &.z-phone {
    position: relative;
    width: 100%;
    user-select: none;
    padding: pxtorem(52) pxtorem(60);

    .m-second-title {
      font-size: pxtorem(28);
      line-height: pxtorem(48);
      color: #6f7993;
      margin-bottom: pxtorem(60);
    }

    .card-header {
      width: 100%;
      height: pxtorem(100);
      padding: pxtorem(30) pxtorem(44);
      background: #f3f5fc;
      border: 1px solid #ededf5;
      box-sizing: border-box;

      .m-line {
        display: inline-block;
        margin-right: pxtorem(22);

        .m-img {
          max-width: 16px;
        }
      }

      .card-title {
        display: inline-block;
        font-size: pxtorem(28);
        line-height: pxtorem(40);
        color: $mainTitleColor;
      }

      .openIcon {
        display: inline-block;
        float: right;
      }
    }

    .card-desc {
      display: none;
    }

    .f-active {
      .imgBg-wrap {
        height: 100%;
      }

      .card-img {
        height: 100%;
        padding-bottom: pxtorem(40);
        background-size: cover;
      }

      .card-desc {
        display: block;
        font-size: pxtorem(24);
        line-height: pxtorem(44);
        margin: pxtorem(30) pxtorem(36) 0;
        color: #6f7993;
        @mixin ellipsis-line 7;
      }
    }
  }
}

.m-product-solution {
  &.z-web {
    width: 1200px;
    margin: 0 auto;
    padding: 90px 0;

    .m-card {
      height: 300px;
      border-radius: 4px;

      .card-img {
        box-sizing: border-box;
        position: absolute;
        display: block;
        z-index: -1;
        background-size: cover;
        overflow: hidden;
        width: 980px;
        height: 300px;
      }

      .m-line {
        display: inline-block;
        padding-left: 80px;
        margin-right: 18px;

        .m-img {
          width: 26px;
          height: 26px;
          vertical-align: bottom;
        }
      }

      .card-title {
        width: 400px;
        display: inline-block;
        margin-top: 92px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: $mainTitleColor;
        text-align: left;
        z-index: 1000;
      }

      .card-desc {
        display: flex;
        padding-top: 16px;
        font-size: 14px;
        line-height: 24px;
        color: #6f7993;
        text-align: justify;
        z-index: 99;
        margin: 0 80px;
      }
    }

    .m-card-border {
      border: 1px solid #e6e9f0;
    }

    .ant-tabs .ant-tabs-left-bar {
      border-right: 1px solid #fff;
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
      width: 300px;
      height: 75px;
      text-align: left;
      background: #f3f5fc;
      color: $mainTitleColor;
      font-size: 16px;
      line-height: 22px;
      padding: 26px 60px;
      margin: 0;
      border-left: 1px solid #e6e9f0;
      border-right: 1px solid #e6e9f0;
      border-top: 1px solid #e6e9f0;

      &:last-child {
        border-bottom: 1px solid #e6e9f0;
      }
    }

    .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
      margin-right: 0;
      border-radius: 4px;
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      background: linear-gradient(90deg, #0056ff 0%, rgba(0, 86, 255, 0.59) 100%) !important;
      color: #fff !important;
      font-size: 16px !important;
      line-height: 22px !important;
      border: 0 !important;
    }

    .ant-tabs-nav .ant-tabs-tab {
      transition: none;
    }

    .ant-tabs-left-bar .ant-tabs-ink-bar {
      width: 0 !important;
      background: #f3f5fc;
    }
  }
}

</style>
